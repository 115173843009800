import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography
} from '@mui/material';
import { estimateItemValue } from 'api/index';
import type {
  EstimateItemType,
  EstimateItemValue,
  GetEstimateItemValueRequest,
  PostPatchEstimateItemValueRequest
} from 'api/types';
import addIcon from 'assets/images/add_circle.svg';
import stylusNoteIcon from 'assets/images/stylus_note.svg';
import EditableTableCell from 'components/EditableTableCell';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import CustomButton from 'components/NewLayout/Button';
import InfoBadge from 'components/NewLayout/InfoBadge';
import DataTable from 'components/NewLayout/Table';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { MAX_11_DIGITS_TWO_DECIMALS_REGEX, MAX_10_DIGITS_ONE_DECIMAL_REGEX } from 'utils/index';

const LabourAccordion = ({
  estimateItemId,
  estimateItemTypes,
  setTotalAmount
}: {
  estimateItemId: number;
  estimateItemTypes: EstimateItemType[];
  setTotalAmount: (val: number) => void;
}) => {
  const [apiInProgress, setApiInProgress] = useState<any>({});
  //fabrication
  const [fabricationList, setFabricationList] = useState<EstimateItemValue[]>([]);
  const [fabricationTotalAmount, setFabricationTotalAmount] = useState<number>(0);
  const [fabricationPerUnitHoursAmount, setFabricationPerUnitHoursAmount] = useState<number>(0);
  const [fabricationPerUnitDaysAmount, setFabricationialPerUnitDaysAmount] = useState<number>(0);
  const [isAddNewRowButtonDisabledFabrication, setAddNewRowButtonDisabilityFabrication] =
    useState<boolean>(false);
  const [isActionsColumnVisibleFabrication, setActionsColumnVisibilityFabrication] =
    useState<boolean>(false);
  const [labourFabricationTypeId, setLabourFabricationTypeId] = useState<number>();
  //paint
  const [paintList, setPaintList] = useState<EstimateItemValue[]>([]);
  const [paintTotalAmount, setPaintTotalAmount] = useState<number>(0);
  const [paintPerUnitHoursAmount, setPaintPerUnitHoursAmount] = useState<number>(0);
  const [paintPerUnitDaysAmount, setPaintPerUnitDaysAmount] = useState<number>(0);
  const [isActionsColumnVisiblePaint, setActionsColumnVisibilityPaint] = useState<boolean>(false);
  const [isAddNewRowButtonDisabledPaint, setAddNewRowButtonDisabilityPaint] =
    useState<boolean>(false);
  const [labourPaintTypeId, setLabourPaintTypeId] = useState<number>();
  //other labour
  const [otherLabourList, setOtherLabourList] = useState<EstimateItemValue[]>([]);
  const [otherLabourTotalAmount, setOtherLabourTotalAmount] = useState<number>(0);
  const [labourCategory, setLabourCategory] = useState<DropdownOptionType[]>([]);
  const [isActionsColumnVisibleOtherLabour, setActionsColumnVisibilityOtherLabour] =
    useState<boolean>(false);
  const [isAddNewRowButtonDisabledOtherLabour, setAddNewRowButtonDisabilityOtherLabour] =
    useState<boolean>(false);

  useEffect(() => {
    const labourCategories: DropdownOptionType[] = [];
    estimateItemTypes.forEach((item) => {
      if (item.estimate_item_type === 'Labour Fabrication') {
        setLabourFabricationTypeId(item.estimate_item_type_id);
      } else if (item.estimate_item_type === 'Labour Paint') {
        setLabourPaintTypeId(item.estimate_item_type_id);
      } else if (item.is_labour) {
        labourCategories.push({
          id: item.estimate_item_type_id,
          value: item.estimate_item_type
        });
      }
    });
    setLabourCategory(labourCategories);
  }, [estimateItemTypes]);

  useEffect(() => {
    labourFabricationTypeId && getFabricationList();
    labourPaintTypeId && getPaintList();
    labourCategory.length && getOtherLabourList();
  }, [labourFabricationTypeId, labourPaintTypeId, labourCategory]);

  useEffect(() => {
    const totals = fabricationList.reduce(
      (acc, item) => {
        if (item.estimate_item_value_id && item.estimate_item_value_id > 0) {
          const labourTotal = Number(item.labour_total);
          const labourPerUnitHours = Number(item.labour_per_unit_hours);
          const labourPerUnitDays = Number(item.labour_per_unit_days);

          acc.totalLabour += isNaN(labourTotal) ? 0 : labourTotal;
          acc.totalHours += isNaN(labourPerUnitHours) ? 0 : labourPerUnitHours;
          acc.totalDays += isNaN(labourPerUnitDays) ? 0 : labourPerUnitDays;
        }
        return acc;
      },
      { totalLabour: 0, totalHours: 0, totalDays: 0 }
    );

    setFabricationTotalAmount(totals.totalLabour);
    setFabricationPerUnitHoursAmount(totals.totalHours);
    setFabricationialPerUnitDaysAmount(totals.totalDays);
    setTotalAmount(totals.totalLabour + paintTotalAmount + otherLabourTotalAmount);
  }, [fabricationList]);

  useEffect(() => {
    const totals = paintList.reduce(
      (acc, item) => {
        if (item.estimate_item_value_id && item.estimate_item_value_id > 0) {
          const labourTotal = Number(item.labour_total);
          const labourPerUnitHours = Number(item.labour_per_unit_hours);
          const labourPerUnitDays = Number(item.labour_per_unit_days);

          acc.totalLabour += isNaN(labourTotal) ? 0 : labourTotal;
          acc.totalHours += isNaN(labourPerUnitHours) ? 0 : labourPerUnitHours;
          acc.totalDays += isNaN(labourPerUnitDays) ? 0 : labourPerUnitDays;
        }
        return acc;
      },
      { totalLabour: 0, totalHours: 0, totalDays: 0 }
    );

    setPaintTotalAmount(totals.totalLabour);
    setPaintPerUnitHoursAmount(totals.totalHours);
    setPaintPerUnitDaysAmount(totals.totalDays);
    setTotalAmount(totals.totalLabour + fabricationTotalAmount + otherLabourTotalAmount);
  }, [paintList]);

  useEffect(() => {
    const totals = otherLabourList.reduce(
      (acc, item) => {
        if (item.estimate_item_value_id && item.estimate_item_value_id > 0) {
          const labourTotal = Number(item.labour_total);
          acc.totalLabour += isNaN(labourTotal) ? 0 : labourTotal;
        }
        return acc;
      },
      { totalLabour: 0 }
    );
    setOtherLabourTotalAmount(totals.totalLabour);
    setTotalAmount(totals.totalLabour + fabricationTotalAmount + paintTotalAmount);
  }, [otherLabourList]);

  const onEditingComplete = (
    fieldName: string,
    val: boolean | string,
    id: number,
    cellId: string,
    table: 'Fabrication' | 'Paint' | 'Other Labour'
  ) => {
    if (!val || val.toString().trim() === '') {
      toast.error('Field is required!', { position: 'top-center' });
      return;
    }

    let isValid = true;

    const validateAndSetList = (
      listSetter: (updater: (prevList: EstimateItemValue[]) => EstimateItemValue[]) => void,
      listGetter: () => EstimateItemValue[],
      regex: RegExp,
      maxLength: number
    ) => {
      if (regex.test(val.toString())) {
        const totalChars = val.toString().replace('.', '').length;
        if (totalChars <= maxLength) {
          const updatedList = listGetter().map((row) => {
            if (row.estimate_item_value_id === id) {
              const updatedRow = { ...row, [fieldName]: val };
              const labourQuantity = Number(updatedRow.labour_quantity);
              const labourHoursPer = Number(updatedRow.labour_hours_per);
              if (!isNaN(labourQuantity) && !isNaN(labourHoursPer)) {
                const labour_per_unit_hours_calculation =
                  Number(labourQuantity) * Number(labourHoursPer);
                updatedRow.labour_total = (labourQuantity * labourHoursPer).toFixed(2);
                updatedRow.labour_per_unit_hours = labour_per_unit_hours_calculation.toFixed(2);
                updatedRow.labour_per_unit_days = (labour_per_unit_hours_calculation / 8).toFixed(
                  2
                );
              }
              return updatedRow;
            }
            return row;
          });
          listSetter(() => updatedList);
          id > 0 && updateEstimateItemValue(id, fieldName, val, updatedList);
          return;
        }
      }
      isValid = false;
    };

    if (table === 'Fabrication') {
      if (fieldName === 'labour_quantity' || fieldName === 'labour_hours_per') {
        validateAndSetList(
          setFabricationList,
          () => fabricationList,
          fieldName === 'labour_quantity'
            ? MAX_10_DIGITS_ONE_DECIMAL_REGEX
            : MAX_11_DIGITS_TWO_DECIMALS_REGEX,
          fieldName === 'labour_quantity' ? 10 : 11
        );
      } else {
        const updatedList = fabricationList.map((row) => {
          if (row.estimate_item_value_id === id) {
            const updatedRow = { ...row, [fieldName]: val };
            return updatedRow;
          }
          return row;
        });
        setFabricationList(() => updatedList);
        id > 0 && updateEstimateItemValue(id, fieldName, val, updatedList);
      }
    } else if (table === 'Paint') {
      if (fieldName === 'labour_quantity' || fieldName === 'labour_hours_per') {
        validateAndSetList(
          setPaintList,
          () => paintList,
          fieldName === 'labour_quantity'
            ? MAX_10_DIGITS_ONE_DECIMAL_REGEX
            : MAX_11_DIGITS_TWO_DECIMALS_REGEX,
          fieldName === 'labour_quantity' ? 10 : 11
        );
      } else {
        const updatedList = paintList.map((row) => {
          if (row.estimate_item_value_id === id) {
            const updatedRow = { ...row, [fieldName]: val };
            return updatedRow;
          }
          return row;
        });
        setPaintList(() => updatedList);
        id > 0 && updateEstimateItemValue(id, fieldName, val, updatedList);
      }
    } else if (table === 'Other Labour') {
      if (fieldName === 'labour_quantity' || fieldName === 'labour_hours_per') {
        validateAndSetList(
          setOtherLabourList,
          () => otherLabourList,
          fieldName === 'labour_quantity'
            ? MAX_10_DIGITS_ONE_DECIMAL_REGEX
            : MAX_11_DIGITS_TWO_DECIMALS_REGEX,
          fieldName === 'labour_quantity' ? 10 : 11
        );
      } else {
        const updatedList = otherLabourList.map((row) => {
          if (row.estimate_item_value_id === id) {
            const updatedRow = { ...row, [fieldName]: val };
            return updatedRow;
          }
          return row;
        });
        setOtherLabourList(() => updatedList);
        id > 0 && updateEstimateItemValue(id, fieldName, val, updatedList);
      }
    }

    if (!isValid) {
      toast.error('Invalid value provided.', { position: 'top-center' });
    }

    setApiInProgress((prev) => ({ ...prev, [cellId]: false }));
  };

  const fabricationColumns = [
    isActionsColumnVisibleFabrication && {
      name: 'Actions',
      prop: 'actions',
      data: (row) => {
        if (row.estimate_item_value_id > 0) return null;
        return (
          <>
            <IconButton color="primary" onClick={() => handleDeleteRowFabrication()}>
              <DeleteOutlineIcon sx={{ fontSize: '20px' }} />
            </IconButton>
            <IconButton color="success" onClick={() => handleApproveRowFabrication()}>
              <CheckIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          </>
        );
      }
    },
    {
      name: 'Material Name',
      prop: 'labour_item_name',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-fabrication`;
        return (
          <EditableTableCell
            name="labour_item_name"
            value={row.labour_item_name || ''}
            onEditComplete={(val) =>
              onEditingComplete(
                'labour_item_name',
                val,
                row.estimate_item_value_id,
                cellId,
                'Fabrication'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Quantity',
      prop: 'labour_quantity',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-fabrication`;
        return (
          <EditableTableCell
            name="labour_quantity"
            value={row.labour_quantity || ''}
            type="number"
            min="0"
            onEditComplete={(val) =>
              onEditingComplete(
                'labour_quantity',
                val,
                row.estimate_item_value_id,
                cellId,
                'Fabrication'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Hours Per',
      prop: 'labour_hours_per',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-fabrication`;
        return (
          <EditableTableCell
            name="labour_hours_per"
            value={row.labour_hours_per ? `${row.labour_hours_per}` : ''}
            type="text"
            onEditComplete={(val) =>
              onEditingComplete(
                'labour_hours_per',
                val,
                row.estimate_item_value_id,
                cellId,
                'Fabrication'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Per Unit Hours',
      prop: 'labour_per_unit_hours',
      data: (row) => `${row.labour_per_unit_hours || '0.00'}`
    },
    {
      name: 'Per Unit Days',
      prop: 'labour_per_unit_days',
      data: (row) => `${row.labour_per_unit_days || '0.00'}`
    },
    {
      name: 'Total',
      prop: 'labour_total',
      data: (row) => `${row.labour_total || '0.00'}`
    }
  ];

  const addNewRowFabrication = () => {
    setFabricationList((prevList) => [
      ...prevList,
      {
        estimate_item_value_id: 0,
        estimate_item_id: estimateItemId,
        estimate_item_type_id: labourFabricationTypeId,
        labour_item_name: '',
        labour_quantity: 0,
        labour_labour_hours_per: 0,
        labour_labour_per_unit_hours: 0,
        labour_labour_per_unit_days: 0
      }
    ]);
    setActionsColumnVisibilityFabrication(true);
    setAddNewRowButtonDisabilityFabrication(true);
  };

  const handleDeleteRowFabrication = () => {
    setFabricationList((currentList) => {
      const updatedList = [...currentList];
      updatedList.pop();
      return updatedList;
    });
    setAddNewRowButtonDisabilityFabrication(false);
    setActionsColumnVisibilityFabrication(false);
  };

  const handleApproveRowFabrication = async () => {
    try {
      const lastFabricationItem = fabricationList[fabricationList.length - 1];

      const { estimate_item_type_id, labour_item_name, labour_quantity, labour_hours_per } =
        lastFabricationItem;

      if (!labour_item_name || !labour_quantity || !labour_hours_per) {
        toast.error('All fields must be filled to submit the row!', { position: 'top-center' });
        return;
      }

      const labour_per_unit_hours_calculation = Number(labour_quantity) * Number(labour_hours_per);

      const dataToPost: PostPatchEstimateItemValueRequest = {
        http_method: 'POST',
        data: {
          estimate_item_id: estimateItemId,
          estimate_item_type_id,
          labour_item_name,
          labour_quantity,
          labour_hours_per,
          labour_per_unit_hours: labour_per_unit_hours_calculation.toFixed(2),
          labour_per_unit_days: (labour_per_unit_hours_calculation / 8).toFixed(2),
          labour_total: (Number(labour_quantity) * Number(labour_hours_per)).toFixed(2)
        }
      };

      await estimateItemValue(dataToPost);
      toast.success('Item added successfully!', { position: 'top-center' });
      await getFabricationList();
      setActionsColumnVisibilityFabrication(false);
      setAddNewRowButtonDisabilityFabrication(false);
    } catch (error) {
      console.error('Error adding item:', error);
      toast.error('Failed to add item. Please try again.', { position: 'top-center' });
    }
  };

  const paintColumns = [
    isActionsColumnVisiblePaint && {
      name: 'Actions',
      prop: 'actions',
      data: (row) => {
        if (row.estimate_item_value_id > 0) return null;
        return (
          <>
            <IconButton color="primary" onClick={() => handleDeleteRowPaint()}>
              <DeleteOutlineIcon sx={{ fontSize: '20px' }} />
            </IconButton>
            <IconButton color="success" onClick={() => handleApproveRowPaint()}>
              <CheckIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          </>
        );
      }
    },
    {
      name: 'Material Name',
      prop: 'labour_item_name',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-paint`;
        return (
          <EditableTableCell
            name="labour_item_name"
            value={row.labour_item_name || ''}
            onEditComplete={(val) =>
              onEditingComplete(
                'labour_item_name',
                val,
                row.estimate_item_value_id,
                cellId,
                'Paint'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Quantity',
      prop: 'labour_quantity',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-paint`;
        return (
          <EditableTableCell
            name="labour_quantity"
            value={row.labour_quantity || ''}
            type="number"
            onEditComplete={(val) =>
              onEditingComplete('labour_quantity', val, row.estimate_item_value_id, cellId, 'Paint')
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Hours Per',
      prop: 'labour_hours_per',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-paint`;
        return (
          <EditableTableCell
            name="labour_hours_per"
            value={row.labour_hours_per ? `${row.labour_hours_per}` : ''}
            type="text"
            onEditComplete={(val) =>
              onEditingComplete(
                'labour_hours_per',
                val,
                row.estimate_item_value_id,
                cellId,
                'Paint'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Per Unit Hours',
      prop: 'labour_per_unit_hours',
      data: (row) => `${row.labour_per_unit_hours || '0.00'}`
    },
    {
      name: 'Per Unit Days',
      prop: 'labour_per_unit_days',
      data: (row) => `${row.labour_per_unit_days || '0.00'}`
    },
    {
      name: 'Total',
      prop: 'labour_total',
      data: (row) => `${row.labour_total || '0.00'}`
    }
  ];

  const addNewRowPaint = () => {
    setPaintList((prevList) => [
      ...prevList,
      {
        estimate_item_value_id: 0,
        estimate_item_id: estimateItemId,
        estimate_item_type_id: labourPaintTypeId,
        labour_item_name: '',
        labour_quantity: 0,
        labour_labour_hours_per: 0,
        labour_labour_per_unit_hours: 0,
        labour_labour_per_unit_days: 0
      }
    ]);
    setActionsColumnVisibilityPaint(true);
    setAddNewRowButtonDisabilityPaint(true);
  };

  const handleDeleteRowPaint = () => {
    setPaintList((currentList) => {
      const updatedList = [...currentList];
      updatedList.pop();
      return updatedList;
    });
    setAddNewRowButtonDisabilityPaint(false);
    setActionsColumnVisibilityPaint(false);
  };

  const handleApproveRowPaint = async () => {
    try {
      const lastPaintItem = paintList[paintList.length - 1];

      const { estimate_item_type_id, labour_item_name, labour_quantity, labour_hours_per } =
        lastPaintItem;

      if (!labour_item_name || !labour_quantity || !labour_hours_per) {
        toast.error('All fields must be filled to submit the row!', { position: 'top-center' });
        return;
      }

      const labour_per_unit_hours_calculation = Number(labour_quantity) * Number(labour_hours_per);

      const dataToPost: PostPatchEstimateItemValueRequest = {
        http_method: 'POST',
        data: {
          estimate_item_id: estimateItemId,
          estimate_item_type_id,
          labour_item_name,
          labour_quantity,
          labour_hours_per,
          labour_per_unit_hours: labour_per_unit_hours_calculation.toFixed(2),
          labour_per_unit_days: (labour_per_unit_hours_calculation / 8).toFixed(2),
          labour_total: (Number(labour_quantity) * Number(labour_hours_per)).toFixed(2)
        }
      };

      await estimateItemValue(dataToPost);
      toast.success('Item added successfully!', { position: 'top-center' });
      await getPaintList();
      setActionsColumnVisibilityPaint(false);
      setAddNewRowButtonDisabilityPaint(false);
    } catch (error) {
      console.error('Error adding item:', error);
      toast.error('Failed to add item. Please try again.', { position: 'top-center' });
    }
  };

  const updateEstimateItemValue = async (
    id: number,
    fieldName: string,
    val: string | boolean,
    currentList: EstimateItemValue[]
  ) => {
    try {
      const item = currentList.find((item) => item.estimate_item_value_id === id);

      if (item) {
        const labour_per_unit_hours_calculation =
          Number(item.labour_quantity) * Number(item.labour_hours_per);

        const dataToUpdate: PostPatchEstimateItemValueRequest = {
          http_method: 'PATCH',
          data: {
            estimate_item_value_id: id,
            [fieldName]: val,
            labour_per_unit_hours: labour_per_unit_hours_calculation.toFixed(2),
            labour_per_unit_days: (labour_per_unit_hours_calculation / 8).toFixed(2),
            labour_total: (Number(item.labour_quantity) * Number(item.labour_hours_per)).toFixed(2)
          }
        };

        await estimateItemValue(dataToUpdate);
        toast.success('Item updated successfully!', {
          position: 'top-center'
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('Error updating item.', {
        position: 'top-center'
      });
    }
  };

  const otherLabourColumns = [
    isActionsColumnVisibleOtherLabour && {
      name: 'Actions',
      prop: 'actions',
      data: (row) => {
        if (row.estimate_item_value_id > 0) return null;
        return (
          <>
            <IconButton color="primary" onClick={() => handleDeleteRowOtherLabour()}>
              <DeleteOutlineIcon sx={{ fontSize: '20px' }} />
            </IconButton>
            <IconButton color="success" onClick={() => handleApproveRowOtherLabour()}>
              <CheckIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          </>
        );
      }
    },
    {
      name: 'Other Labour',
      prop: 'estimate_item_type_id',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-other-labour`;
        return (
          <EditableTableCell
            name="estimate_item_type_id"
            value={
              row.estimate_item_type_id
                ? labourCategory.find((v) => v.id == row.estimate_item_type_id)?.value || ''
                : 'Choose One'
            }
            onEditComplete={(val) => {
              val != 'Choose One'
                ? onEditingComplete(
                    'estimate_item_type_id',
                    val,
                    row.estimate_item_value_id,
                    cellId,
                    'Other Labour'
                  )
                : '';
            }}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            isDropdown
            options={labourCategory}
            key={cellId}
          />
        );
      }
    },
    {
      name: 'Quantity',
      prop: 'labour_quantity',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-other-labour`;
        return (
          <EditableTableCell
            name="labour_quantity"
            value={row.labour_quantity || ''}
            type="number"
            min="0"
            onEditComplete={(val) =>
              onEditingComplete(
                'labour_quantity',
                val,
                row.estimate_item_value_id,
                cellId,
                'Other Labour'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Hours Per',
      prop: 'labour_hours_per',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-other-labour`;
        return (
          <EditableTableCell
            name="labour_hours_per"
            value={row.labour_hours_per ? `${row.labour_hours_per}` : ''}
            type="text"
            onEditComplete={(val) =>
              onEditingComplete(
                'labour_hours_per',
                val,
                row.estimate_item_value_id,
                cellId,
                'Other Labour'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Total',
      prop: 'labour_total',
      data: (row) => `${row.labour_total || '0.00'}`
    }
  ];

  const addNewRowOtherLabour = () => {
    setOtherLabourList((prevList) => [
      ...prevList,
      {
        estimate_item_value_id: 0,
        estimate_item_id: estimateItemId,
        estimate_item_type_id: 0,
        labour_quantity: 0,
        labour_labour_hours_per: 0
      }
    ]);
    setActionsColumnVisibilityOtherLabour(true);
    setAddNewRowButtonDisabilityOtherLabour(true);
  };

  const handleDeleteRowOtherLabour = () => {
    setOtherLabourList((currentList) => {
      const updatedList = [...currentList];
      updatedList.pop();
      return updatedList;
    });
    setAddNewRowButtonDisabilityOtherLabour(false);
    setActionsColumnVisibilityOtherLabour(false);
  };

  const handleApproveRowOtherLabour = async () => {
    try {
      const lastOtherLabourItem = otherLabourList[otherLabourList.length - 1];

      const { estimate_item_type_id, labour_quantity, labour_hours_per } = lastOtherLabourItem;

      if (!labour_quantity || !labour_hours_per || !estimate_item_type_id) {
        toast.error('All fields must be filled to submit the row!', { position: 'top-center' });
        return;
      }

      const dataToPost: PostPatchEstimateItemValueRequest = {
        http_method: 'POST',
        data: {
          estimate_item_id: estimateItemId,
          estimate_item_type_id,
          labour_quantity,
          labour_hours_per,
          labour_total: (Number(labour_quantity) * Number(labour_hours_per)).toFixed(2)
        }
      };

      await estimateItemValue(dataToPost);
      toast.success('Item added successfully!', { position: 'top-center' });
      await getOtherLabourList();
      setActionsColumnVisibilityOtherLabour(false);
      setAddNewRowButtonDisabilityOtherLabour(false);
    } catch (error) {
      console.error('Error adding item:', error);
      toast.error('Failed to add item. Please try again.', { position: 'top-center' });
    }
  };

  const getFabricationList = async () => {
    const getData: GetEstimateItemValueRequest = {
      http_method: 'GET',
      data: {
        estimateItemId,
        estimateItemTypeId: labourFabricationTypeId
      }
    };
    const getEstimateItemValue = await estimateItemValue(getData);
    setFabricationList(getEstimateItemValue);
  };

  const getPaintList = async () => {
    const getData: GetEstimateItemValueRequest = {
      http_method: 'GET',
      data: {
        estimateItemId,
        estimateItemTypeId: labourPaintTypeId
      }
    };
    const getEstimateItemValue = await estimateItemValue(getData);
    setPaintList(getEstimateItemValue);
  };

  const getOtherLabourList = async () => {
    const getData: GetEstimateItemValueRequest = {
      http_method: 'GET',
      data: {
        estimateItemId,
        isLabour: true
      }
    };
    const getEstimateItemValue = await estimateItemValue(getData);
    setOtherLabourList(getEstimateItemValue);
  };

  return (
    <Accordion className="mui-accordion">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0px'
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: '500',
                  fontSize: '22px',
                  lineHeight: '30px',
                  textAlign: 'left',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none'
                }}>
                Labour
              </Typography>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: '1px solid #000' }}>
        {/* 1st section  */}
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '26px' }}>Fabrication</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <InfoBadge
                label="Fabrication Total"
                badgeValue={`${fabricationTotalAmount.toFixed(2)}`}
              />
              <InfoBadge
                label="Per Unit Hours"
                badgeValue={`${fabricationPerUnitHoursAmount.toFixed(2)}`}
              />
              <InfoBadge
                label="Per Unit Days"
                badgeValue={`${fabricationPerUnitDaysAmount.toFixed(2)}`}
              />
              <CustomButton
                disabled={isAddNewRowButtonDisabledFabrication}
                sx={{ marginLeft: '5px' }}
                onClick={() => addNewRowFabrication()}
                label={
                  <>
                    <img src={addIcon} style={{ marginRight: '5px' }} /> Add New Row
                  </>
                }
              />
            </Box>
          </Box>
          <Box>
            <DataTable items={fabricationList} columns={fabricationColumns} totalCount={0} />
          </Box>
        </Box>

        {/* 2nd section  */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginY: '10px'
          }}>
          <CustomButton
            sx={{
              border: '1px dashed #335d87',
              backgroundColor: '#fff',
              color: '#335d87',
              display: 'flex',
              alignItems: 'center',
              '&:hover': { backgroundColor: '#fff' }
            }}
            onClick={() => console.log('click')}
            label={
              <>
                <img src={stylusNoteIcon} style={{ marginRight: '5px' }} />
                <span>Move Canvas Here</span>
              </>
            }
          />
        </Box>

        {/* 3rd section  */}
        <Box sx={{ marginTop: '40px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '26px' }}>Paint</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <InfoBadge label="Paint Total" badgeValue={`${paintTotalAmount.toFixed(2)}`} />
              <InfoBadge
                label="Per Unit Hours"
                badgeValue={`${paintPerUnitHoursAmount.toFixed(2)}`}
              />
              <InfoBadge
                label="Per Unit Days"
                badgeValue={`${paintPerUnitDaysAmount.toFixed(2)}`}
              />
              <CustomButton
                disabled={isAddNewRowButtonDisabledPaint}
                sx={{ marginLeft: '5px' }}
                onClick={() => addNewRowPaint()}
                label={
                  <>
                    <img src={addIcon} style={{ marginRight: '5px' }} /> Add New Row
                  </>
                }
              />
            </Box>
          </Box>
          <Box>
            <DataTable items={paintList} columns={paintColumns} totalCount={0} />
          </Box>
        </Box>

        {/* 4th section  */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginY: '10px'
          }}>
          <CustomButton
            sx={{
              border: '1px dashed #335d87',
              backgroundColor: '#fff',
              color: '#335d87',
              display: 'flex',
              alignItems: 'center',
              '&:hover': { backgroundColor: '#fff' }
            }}
            onClick={() => console.log('click')}
            label={
              <>
                <img src={stylusNoteIcon} style={{ marginRight: '5px' }} />
                <span>Move Canvas Here</span>
              </>
            }
          />
        </Box>

        {/* 5th section  */}
        <Box sx={{ marginTop: '40px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '26px' }}>Other Labour</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <CustomButton
                disabled={isAddNewRowButtonDisabledOtherLabour}
                sx={{ marginLeft: '5px' }}
                onClick={() => addNewRowOtherLabour()}
                label={
                  <>
                    <img src={addIcon} style={{ marginRight: '5px' }} /> Add New Row
                  </>
                }
              />
            </Box>
          </Box>
          <Box>
            <DataTable items={otherLabourList} columns={otherLabourColumns} totalCount={0} />
          </Box>
        </Box>

        {/* 6th section  */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginY: '10px'
          }}>
          <CustomButton
            sx={{
              border: '1px dashed #335d87',
              backgroundColor: '#fff',
              color: '#335d87',
              display: 'flex',
              alignItems: 'center',
              '&:hover': { backgroundColor: '#fff' }
            }}
            onClick={() => console.log('click')}
            label={
              <>
                <img src={stylusNoteIcon} style={{ marginRight: '5px' }} />
                <span>Move Canvas Here</span>
              </>
            }
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LabourAccordion;
