import CachedIcon from '@mui/icons-material/Cached';
import { Box, IconButton, Typography } from '@mui/material';
import editIcon from 'assets/images/edit-icon.svg';
import InfoBadge from 'components/NewLayout/InfoBadge';

const TitleHeader = ({
  title,
  quantity,
  totalCost
}: {
  title: string;
  quantity: number;
  totalCost: number;
}) => {
  console.log('');
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Poppins',
              fontSize: '28px',
              fontWeight: 500,
              lineHeight: '42px',
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none'
            }}>
            {title}
          </Typography>

          <IconButton>
            <img src={editIcon} width={20} />
          </IconButton>
          {/* <IconButton color="error">
            <CachedIcon />
          </IconButton> */}
        </Box>
        <Box sx={{ display: 'flex' }}>
          <InfoBadge label="Quantity" badgeValue={quantity} />
          <InfoBadge label="Total Cost" badgeValue={`$${totalCost.toFixed(2)}`} />
        </Box>
      </Box>
    </>
  );
};

export default TitleHeader;
