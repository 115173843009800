import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  Typography
} from '@mui/material';
import { estimateItemValue } from 'api/index';
import type {
  EstimateItemType,
  EstimateItemValue,
  GetEstimateItemValueRequest,
  PostPatchEstimateItemValueRequest
} from 'api/types';
import addIcon from 'assets/images/add_circle.svg';
import stylusNoteIcon from 'assets/images/stylus_note.svg';
import EditableTableCell from 'components/EditableTableCell';
import CustomButton from 'components/NewLayout/Button';
import InfoBadge from 'components/NewLayout/InfoBadge';
import DataTable from 'components/NewLayout/Table';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { MAX_11_DIGITS_TWO_DECIMALS_REGEX, MAX_10_DIGITS_ONE_DECIMAL_REGEX } from 'utils/index';

const MaterialAccordion = ({
  estimateItemId,
  estimateItemTypes,
  setTotalAmount
}: {
  estimateItemId: number;
  estimateItemTypes: EstimateItemType[];
  setTotalAmount: (val: number) => void;
}) => {
  const [apiInProgress, setApiInProgress] = useState<any>({});

  const [fabricationList, setFabricationList] = useState<EstimateItemValue[]>([]);
  const [fabricationTotalAmount, setFabricationTotalAmount] = useState<number>(0);
  const [fabricationNonValueTotalAmount, setFabricationNonValueTotalAmount] = useState<number>(0);
  const [isAddNewRowButtonDisabledFabrication, setAddNewRowButtonDisabilityFabrication] =
    useState<boolean>(false);
  const [isActionsColumnVisibleFabrication, setActionsColumnVisibilityFabrication] =
    useState<boolean>(false);
  const [materialFabricationTypeId, setMaterialFabricationTypeId] = useState<number>();

  const [paintList, setPaintList] = useState<EstimateItemValue[]>([]);
  const [paintTotalAmount, setPaintTotalAmount] = useState<number>(0);
  const [paintNonValueTotalAmount, setPaintNonValueTotalAmount] = useState<number>(0);
  const [isActionsColumnVisiblePaint, setActionsColumnVisibilityPaint] = useState<boolean>(false);
  const [isAddNewRowButtonDisabledPaint, setAddNewRowButtonDisabilityPaint] =
    useState<boolean>(false);
  const [materialPaintTypeId, setMaterialPaintTypeId] = useState<number>();

  useEffect(() => {
    estimateItemTypes.map((item) => {
      if (item.estimate_item_type === 'Material Fabrication') {
        setMaterialFabricationTypeId(item.estimate_item_type_id);
      } else if (item.estimate_item_type === 'Material Paint') {
        setMaterialPaintTypeId(item.estimate_item_type_id);
      }
    });
  }, []);

  useEffect(() => {
    materialFabricationTypeId && getFabricationList();
    materialPaintTypeId && getPaintList();
  }, [materialFabricationTypeId, materialPaintTypeId]);

  useEffect(() => {
    const { totalAmount, totalNonValueAmount } = fabricationList.reduce(
      (totals, item) => {
        if (item.estimate_item_value_id && item.estimate_item_value_id > 0) {
          const materialTotal = Number(item.material_total);
          const materialNonValueTotal = Number(item.material_non_value_total);
          return {
            totalAmount: totals.totalAmount + (isNaN(materialTotal) ? 0 : materialTotal),
            totalNonValueAmount:
              totals.totalNonValueAmount +
              (isNaN(materialNonValueTotal) ? 0 : materialNonValueTotal)
          };
        }
        return totals;
      },
      { totalAmount: 0, totalNonValueAmount: 0 }
    );

    setFabricationTotalAmount(totalAmount);
    setFabricationNonValueTotalAmount(totalNonValueAmount);
    setTotalAmount(totalAmount + paintTotalAmount);
  }, [fabricationList]);

  useEffect(() => {
    const { totalAmount, totalNonValueAmount } = paintList.reduce(
      (totals, item) => {
        if (item.estimate_item_value_id && item.estimate_item_value_id > 0) {
          const materialTotal = Number(item.material_total);
          const materialNonValueTotal = Number(item.material_non_value_total);
          return {
            totalAmount: totals.totalAmount + (isNaN(materialTotal) ? 0 : materialTotal),
            totalNonValueAmount:
              totals.totalNonValueAmount +
              (isNaN(materialNonValueTotal) ? 0 : materialNonValueTotal)
          };
        }
        return totals;
      },
      { totalAmount: 0, totalNonValueAmount: 0 }
    );

    setPaintTotalAmount(totalAmount);
    setPaintNonValueTotalAmount(totalNonValueAmount);
    setTotalAmount(totalAmount + fabricationTotalAmount);
  }, [paintList]);

  const onEditingComplete = (
    fieldName: string,
    val: boolean | string,
    id: number,
    cellId: string,
    table: 'Fabrication' | 'Paint'
  ) => {
    if (val === null || val === undefined || val.toString().trim() === '') {
      toast.error('Field is required!', { position: 'top-center' });
      return;
    }

    let isValid = true;

    const validateAndSetList = (
      listSetter: (updater: (prevList: EstimateItemValue[]) => EstimateItemValue[]) => void,
      listGetter: () => EstimateItemValue[],
      regex: RegExp,
      maxLength: number
    ) => {
      if (regex.test(val.toString())) {
        const totalChars = val.toString().replace('.', '').length;
        if (totalChars <= maxLength) {
          const updatedList = listGetter().map((row) => {
            if (row.estimate_item_value_id === id) {
              const updatedRow = { ...row, [fieldName]: val };

              const materialQuantity = Number(updatedRow.material_quantity);
              const materialUnitCost = Number(updatedRow.material_unit_cost);

              if (!isNaN(materialQuantity) && !isNaN(materialUnitCost)) {
                updatedRow.material_total = (materialQuantity * materialUnitCost).toFixed(2);
                if (updatedRow.material_non_value) {
                  updatedRow.material_non_value_total = (
                    materialQuantity * materialUnitCost
                  ).toFixed(2);
                } else {
                  updatedRow.material_non_value_total = 0;
                }
              }

              return updatedRow;
            }
            return row;
          });

          listSetter(() => updatedList);

          if (id > 0) {
            updateEstimateItemValue(id, fieldName, val, updatedList);
          }
          return;
        }
      }
      isValid = false;
    };

    const updateList = (
      listSetter: (updater: (prevList: EstimateItemValue[]) => EstimateItemValue[]) => void,
      listGetter: () => EstimateItemValue[]
    ) => {
      const updatedList = listGetter().map((row) => {
        if (row.estimate_item_value_id === id) {
          const materialQuantity = Number(row.material_quantity);
          const materialUnitCost = Number(row.material_unit_cost);

          let materialTotal = 0;
          if (!isNaN(materialQuantity) && !isNaN(materialUnitCost)) {
            materialTotal = materialQuantity * materialUnitCost;
          }

          let updatedRow;

          if (fieldName === 'material_non_value') {
            updatedRow = {
              ...row,
              [fieldName]: val,
              material_non_value_total: val ? materialTotal.toFixed(2) : 0
            };
          } else {
            updatedRow = {
              ...row,
              [fieldName]: val
            };
          }

          return updatedRow;
        }
        return row;
      });

      listSetter(() => updatedList);

      if (id > 0) {
        updateEstimateItemValue(id, fieldName, val, updatedList);
      }
    };

    if (fieldName === 'material_quantity' || fieldName === 'material_unit_cost') {
      validateAndSetList(
        table === 'Fabrication' ? setFabricationList : setPaintList,
        table === 'Fabrication' ? () => fabricationList : () => paintList,
        fieldName === 'material_quantity'
          ? MAX_10_DIGITS_ONE_DECIMAL_REGEX
          : MAX_11_DIGITS_TWO_DECIMALS_REGEX,
        fieldName === 'material_quantity' ? 10 : 11
      );
    } else {
      updateList(
        table === 'Fabrication' ? setFabricationList : setPaintList,
        table === 'Fabrication' ? () => fabricationList : () => paintList
      );
    }

    if (!isValid) {
      toast.error('Invalid value provided.', { position: 'top-center' });
    }

    setApiInProgress((prev) => ({ ...prev, [cellId]: false }));
  };

  const materialsListColumns = [
    isActionsColumnVisibleFabrication && {
      name: 'Actions',
      prop: 'actions',
      data: (row) => {
        if (row.estimate_item_value_id > 0) return null;
        return (
          <>
            <IconButton color="primary" onClick={() => handleDeleteRowFabrication()}>
              <DeleteOutlineIcon sx={{ fontSize: '20px' }} />
            </IconButton>
            <IconButton color="success" onClick={() => handleApproveRowFabrication()}>
              <CheckIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          </>
        );
      }
    },
    {
      name: 'Non Value',
      prop: 'material_non_value',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-fabrication`;
        return (
          <Checkbox
            id={cellId}
            checked={row.material_non_value || false}
            onChange={(e) =>
              onEditingComplete(
                'material_non_value',
                e.target.checked,
                row.estimate_item_value_id,
                cellId,
                'Fabrication'
              )
            }
          />
        );
      }
    },
    {
      name: 'Material Name',
      prop: 'material_name',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-fabrication`;
        return (
          <EditableTableCell
            name="material_name"
            value={row.material_name || ''}
            type="text"
            onEditComplete={(val) =>
              onEditingComplete(
                'material_name',
                val,
                row.estimate_item_value_id,
                cellId,
                'Fabrication'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Quantity',
      prop: 'material_quantity',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-fabrication`;
        return (
          <EditableTableCell
            name="material_quantity"
            value={row.material_quantity || ''}
            type="number"
            onEditComplete={(val) =>
              onEditingComplete(
                'material_quantity',
                val,
                row.estimate_item_value_id,
                cellId,
                'Fabrication'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Unit Cost',
      prop: 'material_unit_cost',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-fabrication`;
        return (
          <EditableTableCell
            name="material_unit_cost"
            value={row.material_unit_cost ? `$${row.material_unit_cost}` : ''}
            type="number"
            onEditComplete={(val) =>
              onEditingComplete(
                'material_unit_cost',
                val,
                row.estimate_item_value_id,
                cellId,
                'Fabrication'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Total',
      prop: 'material_total',
      data: (row) => `$${row.material_total || '0.00'}`
    },
    {
      name: 'Non Value Total',
      prop: 'material_non_value_total',
      data: (row) => `$${row.material_non_value_total || '0.00'}`
    }
  ];

  const addNewRowFabrication = () => {
    setFabricationList((prevList) => [
      ...prevList,
      {
        estimate_item_value_id: 0,
        estimate_item_type_id: materialFabricationTypeId,
        material_non_value: false,
        material_name: '',
        material_quantity: 0,
        material_unit_cost: 0
      }
    ]);
    setActionsColumnVisibilityFabrication(true);
    setAddNewRowButtonDisabilityFabrication(true);
  };

  const handleDeleteRowFabrication = () => {
    setFabricationList((currentList) => {
      const updatedList = [...currentList];
      updatedList.pop();
      return updatedList;
    });
    setAddNewRowButtonDisabilityFabrication(false);
    setActionsColumnVisibilityFabrication(false);
  };

  const handleApproveRowFabrication = async () => {
    try {
      const lastFabricationItem = fabricationList[fabricationList.length - 1];

      const {
        estimate_item_type_id,
        material_non_value,
        material_name,
        material_quantity,
        material_unit_cost
      } = lastFabricationItem;

      if (!material_name || !material_quantity || !material_unit_cost) {
        toast.error('All fields must be filled to submit the row!', {
          position: 'top-center'
        });
        return;
      }

      const material_total = (Number(material_quantity) * Number(material_unit_cost)).toFixed(2);

      const dataToPost: PostPatchEstimateItemValueRequest = {
        http_method: 'POST',
        data: {
          estimate_item_id: estimateItemId,
          estimate_item_type_id,
          material_non_value,
          material_name,
          material_quantity,
          material_unit_cost,
          material_total,
          material_non_value_total: material_non_value ? material_total : 0
        }
      };

      await estimateItemValue(dataToPost);
      toast.success('Item added successfully!', { position: 'top-center' });
      await getFabricationList();
      setActionsColumnVisibilityFabrication(false);
      setAddNewRowButtonDisabilityFabrication(false);
    } catch (error) {
      console.error('Error adding item:', error);
      toast.error('Failed to add item. Please try again.', {
        position: 'top-center'
      });
    }
  };

  const paintColumns = [
    isActionsColumnVisiblePaint && {
      name: 'Actions',
      prop: 'actions',
      data: (row) => {
        if (row.estimate_item_value_id > 0) return null;
        return (
          <>
            <IconButton color="primary" onClick={() => handleDeleteRowPaint()}>
              <DeleteOutlineIcon sx={{ fontSize: '20px' }} />
            </IconButton>
            <IconButton color="success" onClick={() => handleApproveRowPaint()}>
              <CheckIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          </>
        );
      }
    },
    {
      name: 'Non Value',
      prop: 'material_non_value',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-paint`;
        return (
          <Checkbox
            id={cellId}
            checked={row.material_non_value || false}
            onChange={(e) =>
              onEditingComplete(
                'material_non_value',
                e.target.checked,
                row.estimate_item_value_id,
                cellId,
                'Paint'
              )
            }
          />
        );
      }
    },
    {
      name: 'Material Name',
      prop: 'material_name',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-paint`;
        return (
          <EditableTableCell
            name="material_name"
            value={row.material_name || ''}
            type="text"
            onEditComplete={(val) =>
              onEditingComplete('material_name', val, row.estimate_item_value_id, cellId, 'Paint')
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Quantity',
      prop: 'material_quantity',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-paint`;
        return (
          <EditableTableCell
            name="material_quantity"
            value={row.material_quantity || ''}
            type="number"
            onEditComplete={(val) =>
              onEditingComplete(
                'material_quantity',
                val,
                row.estimate_item_value_id,
                cellId,
                'Paint'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Unit Cost',
      prop: 'material_unit_cost',
      data: (row, index) => {
        const cellId = `${row.estimate_item_value_id}-${index}-paint`;
        return (
          <EditableTableCell
            name="material_unit_cost"
            value={row.material_unit_cost ? `$${row.material_unit_cost}` : ''}
            type="number"
            onEditComplete={(val) =>
              onEditingComplete(
                'material_unit_cost',
                val,
                row.estimate_item_value_id,
                cellId,
                'Paint'
              )
            }
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Total',
      prop: 'material_total',
      data: (row) => `$${row.material_total || '0.00'}`
    },
    {
      name: 'Non Value Total',
      prop: 'material_non_value_total',
      data: (row) => `$${row.material_non_value_total || '0.00'}`
    }
  ];

  const addNewRowPaint = () => {
    setPaintList((prevList) => [
      ...prevList,
      {
        estimate_item_id: estimateItemId,
        estimate_item_value_id: 0,
        estimate_item_type_id: materialPaintTypeId,
        material_non_value: false,
        material_name: '',
        material_quantity: 0,
        material_unit_cost: 0
      }
    ]);
    setActionsColumnVisibilityPaint(true);
    setAddNewRowButtonDisabilityPaint(true);
  };

  const handleDeleteRowPaint = () => {
    setPaintList((currentList) => {
      const updatedList = [...currentList];
      updatedList.pop();
      return updatedList;
    });
    setAddNewRowButtonDisabilityPaint(false);
    setActionsColumnVisibilityPaint(false);
  };

  const handleApproveRowPaint = async () => {
    try {
      const lastPaintItem = paintList[paintList.length - 1];

      const {
        estimate_item_type_id,
        material_non_value,
        material_name,
        material_quantity,
        material_unit_cost
      } = lastPaintItem;

      if (!material_name || !material_quantity || !material_unit_cost) {
        toast.error('All fields must be filled to approve the row!', {
          position: 'top-center'
        });
        return;
      }

      const material_total = (Number(material_quantity) * Number(material_unit_cost)).toFixed(2);

      const dataToPost: PostPatchEstimateItemValueRequest = {
        http_method: 'POST',
        data: {
          estimate_item_id: estimateItemId,
          estimate_item_type_id,
          material_non_value,
          material_name,
          material_quantity,
          material_unit_cost,
          material_total,
          material_non_value_total: material_non_value ? material_total : 0
        }
      };

      await estimateItemValue(dataToPost);
      toast.success('Item added successfully!', { position: 'top-center' });
      await getPaintList();
      setActionsColumnVisibilityPaint(false);
      setAddNewRowButtonDisabilityPaint(false);
    } catch (error) {
      console.error('Error adding paint item:', error);
      toast.error('Failed to add the item. Please try again.', {
        position: 'top-center'
      });
    }
  };

  const updateEstimateItemValue = async (
    id: number,
    fieldName: string,
    val: string | boolean,
    currentList: EstimateItemValue[]
  ) => {
    try {
      const item = currentList.find((item) => item.estimate_item_value_id === id);

      if (item) {
        const material_total = (
          Number(item.material_quantity) * Number(item.material_unit_cost)
        ).toFixed(2);

        const dataToUpdate: PostPatchEstimateItemValueRequest = {
          http_method: 'PATCH',
          data: {
            estimate_item_value_id: id,
            [fieldName]: val,
            material_total,
            material_non_value_total: item.material_non_value ? material_total : 0
          }
        };

        await estimateItemValue(dataToUpdate);
        toast.success('Item updated successfully!', {
          position: 'top-center'
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('Error updating item.', {
        position: 'top-center'
      });
    }
  };

  const getFabricationList = async () => {
    const getData: GetEstimateItemValueRequest = {
      http_method: 'GET',
      data: {
        estimateItemId,
        estimateItemTypeId: materialFabricationTypeId
      }
    };
    const getEstimateItemValue = await estimateItemValue(getData);
    setFabricationList(getEstimateItemValue);
  };

  const getPaintList = async () => {
    const getData: GetEstimateItemValueRequest = {
      http_method: 'GET',
      data: {
        estimateItemId,
        estimateItemTypeId: materialPaintTypeId
      }
    };
    const getEstimateItemValue = await estimateItemValue(getData);
    setPaintList(getEstimateItemValue);
  };

  return (
    <>
      <Accordion className="mui-accordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '0px'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <Box>
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: '500',
                    fontSize: '22px',
                    lineHeight: '30px',
                    textAlign: 'left',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none'
                  }}>
                  Materials
                </Typography>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: '1px solid #000' }}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginY: '10px'
              }}>
              <Box>
                <Typography sx={{ fontSize: '26px' }}>Fabrication</Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <InfoBadge
                  label="Fabrication Total"
                  badgeValue={`$${fabricationTotalAmount.toFixed(2)}`}
                />
                <InfoBadge
                  label="Non Value Total"
                  badgeValue={`$${fabricationNonValueTotalAmount.toFixed(2)}`}
                />
                <CustomButton
                  disabled={isAddNewRowButtonDisabledFabrication}
                  sx={{ marginLeft: '5px' }}
                  onClick={addNewRowFabrication}
                  label={
                    <>
                      <img src={addIcon} style={{ marginRight: '5px' }} /> Add New Row
                    </>
                  }
                />
              </Box>
            </Box>
            <Box>
              <DataTable
                items={fabricationList}
                columns={materialsListColumns}
                totalCount={fabricationList.length}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginY: '10px'
            }}>
            <CustomButton
              sx={{
                border: '1px dashed #335d87',
                backgroundColor: '#fff',
                color: '#335d87',
                display: 'flex',
                alignItems: 'center',
                '&:hover': { backgroundColor: '#fff' }
              }}
              onClick={() => console.log('click')}
              label={
                <>
                  <img src={stylusNoteIcon} style={{ marginRight: '5px' }} />
                  <span>Move Canvas Here</span>
                </>
              }
            />
          </Box>

          <Box sx={{ marginTop: '40px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginY: '10px'
              }}>
              <Box>
                <Typography sx={{ fontSize: '26px' }}>Paint</Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <InfoBadge label="Paint Total" badgeValue={`$${paintTotalAmount.toFixed(2)}`} />
                <InfoBadge
                  label="Non Value Total"
                  badgeValue={`$${paintNonValueTotalAmount.toFixed(2)}`}
                />
                <CustomButton
                  disabled={isAddNewRowButtonDisabledPaint}
                  sx={{ marginLeft: '5px' }}
                  onClick={addNewRowPaint}
                  label={
                    <>
                      <img src={addIcon} style={{ marginRight: '5px' }} /> Add New Row
                    </>
                  }
                />
              </Box>
            </Box>
            <Box>
              <DataTable items={paintList} columns={paintColumns} totalCount={paintList.length} />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default MaterialAccordion;
