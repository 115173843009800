import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  forwardRef,
  useImperativeHandle
} from 'react';
import type { ChangeEvent } from 'react';
import DropDown from 'components/NewLayout/Dropdown';
import type { Vendor } from 'api/types';
import toast from 'react-hot-toast';
import { postVendor, updateVendor } from 'api/index';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import {
  ALPHA_NUMERIC_DASH_REGEX,
  TAX_VALIDATION_REGEX,
  getValidations,
  validatePhoneNumber
} from 'utils/index';
import GooglePlacesAutocompleteComp from 'components/inputs/GooglePlacesAutocomplete';
import Validator from 'simple-react-validator';
import TextField from 'components/NewLayout/Textfield';
import DrawerForm from 'components/NewLayout/DrawerForm';
import CustomButton from 'components/NewLayout/Button';
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { useAdminWorkspaceStore } from 'store/index';
import type { AdminHubChildComponentRef } from 'utils/types';
import CustomDialog from 'components/NewLayout/Dialog';
import { descriptionMaxLength, nameMaxLength } from 'utils/constants';

export const billingAccounts = [
  { id: '80000027-1427077543', value: 'Materials' },
  { id: '80000024-1425430137', value: 'Vendor or Contractor' },
  { id: '80000008-1421889154', value: 'Freight and Shipping' },
  { id: '80000034-1437017488', value: 'Miscellaneous Tools' },
  { id: '8000016F-1642792895', value: 'Trade Shows and Conventions' },
  { id: '800000C1-1503680639', value: 'Airfare' },
  { id: '8000006B-1467235962', value: 'Cab/Uber/Lyft' },
  { id: '800001B6-1693489494', value: 'Car Rental' },
  { id: '80000071-1467912643', value: 'Hotel' },
  { id: '800000DD-1521041571', value: 'Mileage' },
  { id: '80000069-1467235883', value: 'Parking' },
  { id: '80000096-1482893190', value: 'Tools over $2k' },
  { id: '800000A1-1484688299', value: 'Computer Equipment' },
  { id: '800000F0-1532555440', value: 'Computer Software' },
  { id: '80000068-1467234044', value: 'Auto:Gas' },
  { id: '80000084-1478200605', value: 'Auto:Repairs' },
  { id: '8000000B-1421889154', value: 'Computer Supplies' },
  { id: '8000000D-1421889154', value: 'Dues and Subscriptions' },
  { id: '80000011-1421889154', value: 'Office Supplies' },
  { id: '800000A0-1484687387', value: 'Materials:Incidentals' },
  { id: '800000AE-1492196854', value: 'Licenses & Fees' },
  { id: '80000101-1548453570', value: 'Meals - Business' },
  { id: '800000E8-1530305730', value: 'Laser Supplies' },
  { id: '800000D1-1518212371', value: 'Recruiting' },
  { id: '80000056-1455668058', value: 'Shop Supplies: Bits-Blades' },
  { id: '80000057-1455668079', value: 'Shop Supplies: Carpentry' },
  { id: '800001B2-1692038796', value: 'Shop Supplies: Chemical Disposal' },
  { id: '80000055-1455667998', value: 'Shop Supplies: Metal Shop' },
  { id: '80000054-1455667387', value: 'Shop Supplies: Paint Supplies' },
  { id: '8000009F-1484684305', value: 'Shop Supplies: Printing' },
  { id: '80000077-1472241339', value: 'Shop Supplies: 3D Printing' },
  { id: '800000F4-1537570009', value: 'Shop Supplies: CNC Routers' },
  { id: '8000008A-1479930534', value: 'Shop Supplies: Flat Bed Printer' },
  { id: '800000C2-1504895706', value: 'Shop Supplies: Robot Supplies' },
  { id: '80000035-1438304706', value: 'Truck Rental' }
];

export default function CreateVendor({
  open,
  setOpen,
  action,
  allVendors,
  onVendorCreation
}: {
  open: boolean;
  setOpen: (status: boolean) => void;
  action: 'create' | 'update';
  allVendors?: Vendor[];
  onVendorCreation?: () => void;
}) {
  const childRef = useRef<AdminHubChildComponentRef>();
  return (
    <DrawerForm
      open={open}
      closeDrawer={() => setOpen(false)}
      heading={`${action === 'create' ? 'Create' : 'Update'}  A Vendor`}
      actions={
        <>
          <CustomButton variant="outlined" label="Discard" onClick={() => setOpen(false)} />
          <CustomButton
            label="Submit Request"
            onClick={() => {
              if (childRef.current) {
                childRef.current.submitForm();
              }
            }}
          />
        </>
      }>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography component="em" color="textSecondary">
            Enter the details below to create a new vendor.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <VendorForm
            action={action}
            ref={childRef}
            onComplete={() => setOpen(false)}
            allVendors={allVendors}
            onVendorCreation={onVendorCreation}
          />
        </Grid>
      </Grid>
    </DrawerForm>
  );
}

const initialObj = {
  vendor_name: '',
  is_active: false,
  created_by: '',
  last_updated_by: '',
  created_at: '',
  last_updated_at: '',
  phone_number: '',
  is_net_vendor: false,
  payment_terms: '',
  default_payment_method: '',
  billing_account: '',
  billed_from_country: '',
  appearance_order_and_logistic: true,
  appearance_time_tracting_and_attendance: true
};

const VendorForm = forwardRef(
  (
    {
      action,
      onComplete,
      allVendors,
      onVendorCreation
    }: {
      action: 'update' | 'create';
      onComplete: () => void;
      allVendors?: Vendor[];
      onVendorCreation?: () => void;
    },
    ref
  ) => {
    const [data, setData] = useState<Vendor>(initialObj);
    const [shippedFromAddrIsSameAsBilledFromAddr, setShippedFromAddrIsSameAsBilledFromAddr] =
      useState<boolean>(false);
    const validator = useRef(new Validator(getValidations()));
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const theme = useTheme();
    const [warningMessage, setWarningMessage] = useState<string>('');

    const vendor = useAdminWorkspaceStore((state) => state.selectedVendor);
    const updateStoreVendor = useAdminWorkspaceStore((state) => state.updateVendor);
    const [showSuggest, setShowSuggest] = useState<boolean>(false);
    const [nameSuggestion, setNameSuggestions] = useState<string[]>([]);
    let i = 0;

    const handleSuggestions = (suggestion: string) => {
      i = 0;
      setData({ ...data, vendor_name: suggestion });
      setShowSuggest(false);
    };

    useEffect(() => {
      if (vendor) {
        setData(vendor);
      }
    }, [vendor]);
    useImperativeHandle(ref, () => ({
      submitForm() {
        if (validator.current.allValid()) {
          const dataToSave = { ...data };

          if (dataToSave.tax_ID && TAX_VALIDATION_REGEX.test(dataToSave.tax_ID!) != true) {
            toast.error('Enter the correct format for Tax ID XX-XXXXXXX', {
              position: 'top-center'
            });
            return;
          }
          if (dataToSave.phone_number && validatePhoneNumber(dataToSave.phone_number)) {
            if (!dataToSave.phone_number.includes('+')) {
              dataToSave.phone_number = `+${dataToSave.phone_number}`;
            }
            if (action === 'create') {
              const loadingToast = toast.loading('Adding...', {
                position: 'top-center'
              });
              postVendor(dataToSave)
                .then((res: { message: string }) => {
                  toast.dismiss(loadingToast);
                  if (res.message === 'Vendor added successfully!') {
                    toast.success('Vendor has been created and is waiting approval!', {
                      position: 'top-center'
                    });
                    onComplete();
                    if (onVendorCreation) {
                      onVendorCreation();
                    }
                  }
                })
                .catch((err) => {
                  toast.dismiss(loadingToast);
                  const { response } = err;
                  if (response?.status === 409) {
                    setShowSuggest(true);
                    const suggestions = Array.from({ length: 3 }, () => {
                      const randomNum = Math.floor(1000 + Math.random() * 9000);
                      return `${dataToSave.vendor_name}${randomNum}`;
                    });
                    setNameSuggestions(suggestions);
                  }
                });
            } else {
              const loadingToast = toast.loading('Updating...', {
                position: 'top-center'
              });
              if (data.vendor_id) {
                updateVendor(data, data.vendor_id ?? '')
                  .then((res: { message: string }) => {
                    toast.dismiss(loadingToast);
                    if (res.message === 'vendor updated successfully!') {
                      updateStoreVendor(data);
                      toast.success('Vendor has been updated!', {
                        position: 'top-center'
                      });
                    }
                    onComplete();
                  })
                  .catch((err) => {
                    toast.dismiss(loadingToast);
                    console.log(err);
                  });
              }
            }
          }
        } else {
          validator.current.showMessages();
          forceUpdate();
        }
      }
    }));

    const handleChange = ({
      target: { name, value }
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (name == 'vendor_name' && value.length > 40) {
        toast.error('Maximum length should not be more than 40 characters.', {
          position: 'top-center'
        });
        return;
      }
      let updatedData = {
        ...data,
        [name]: value === 'true' ? true : value === 'false' ? false : value
      };
      if (name == 'payment_terms' && value == 'Credit Card') {
        updatedData = {
          ...updatedData,
          ['default_payment_method']: value
        };
      }

      if (!shippedFromAddrIsSameAsBilledFromAddr) {
        if (
          [
            'billed_from_address1',
            'billed_from_address2',
            'billed_from_city',
            'billed_from_country',
            'billed_from_postal_code',
            'billed_from_state'
          ].includes(name)
        ) {
          const key = name.split('billed')[1];
          updatedData = { ...updatedData, [`shipped${key}`]: value };
        }
      }
      if (value.length == 2 && data.tax_ID && !data.tax_ID.includes('-')) {
        updatedData.tax_ID = value + '-';
      }
      setData(updatedData);
    };

    const onCheckShippedAddressAsBilledAddress = (isChecked: boolean) => {
      setShippedFromAddrIsSameAsBilledFromAddr(isChecked);
      const updatedData = {
        ...data,
        shipped_from_address1: !isChecked ? data.billed_from_address1 : '',
        shipped_from_address2: !isChecked ? data.billed_from_address2 : '',
        shipped_from_city: !isChecked ? data.billed_from_city : '',
        shipped_from_country: !isChecked ? data.billed_from_country : '',
        shipped_from_postal_code: !isChecked ? data.billed_from_postal_code : '',
        shipped_from_state: !isChecked ? data.billed_from_state : ''
      };
      setData(updatedData);
    };

    const updateAddress = (
      { city, country, state, postalCode, addressLine1 }: any,
      addressType: string
    ) => {
      const updatedData = {
        ...data,
        [`${addressType}_from_city`]: city ?? '',
        [`${addressType}_from_country`]: country ?? '',
        [`${addressType}_from_state`]: state ?? '',
        [`${addressType}_from_postal_code`]: postalCode ?? '',
        [`${addressType}_from_address1`]: addressLine1 ?? '',
        ...(addressType === 'billed' && !shippedFromAddrIsSameAsBilledFromAddr
          ? {
              shipped_from_city: city ?? '',
              shipped_from_country: country ?? '',
              shipped_from_state: state ?? '',
              shipped_from_postal_code: postalCode ?? '',
              shipped_from_address1: addressLine1 ?? ''
            }
          : {})
      };

      setData(updatedData);
    };

    validator.current.purgeFields();

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              labelText="Company Name"
              name="vendor_name"
              placeholder="Company Name"
              value={data.vendor_name}
              onChange={handleChange}
              isRequired={true}
              validator={validator}
              disabled={action === 'update' && !vendor}
            />
            {showSuggest ? (
              <Box>
                {nameSuggestion.map((names, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    className="rounded-pill"
                    sx={{ padding: '2px, 3px', marginX: '5px' }}
                    onClick={() => handleSuggestions(names)}>
                    {names}
                  </Button>
                ))}
              </Box>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              labelText="Billing Contact Name"
              name="billing_contact"
              placeholder="Full Name"
              value={data.billing_contact}
              onChange={handleChange}
              isRequired={false}
              disabled={action === 'update' && !vendor}
              maxlength={nameMaxLength}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneNumberInput
              label="Billing Contact Number"
              name="billing_contact_number"
              handleChange={handleChange}
              disabled={action === 'update' && !vendor}
              value={data.billing_contact_number}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              labelText="Account Payable Contact Name"
              name="account_payable_contact"
              placeholder="Full Name"
              value={data.account_payable_contact}
              onChange={handleChange}
              isRequired={false}
              disabled={action === 'update' && !vendor}
              maxlength={nameMaxLength}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneNumberInput
              label="Account Payable Contact Number"
              name="account_payable_contact_number"
              handleChange={handleChange}
              disabled={action === 'update' && !vendor}
              value={data.account_payable_contact_number}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DropDown
              label="Payment Terms"
              inputName="payment_terms"
              optionText="Choose..."
              value={data.payment_terms || ''}
              required
              onChange={handleChange}
              options={[
                { id: 'Check', value: 'Check' },
                { id: 'Credit Card', value: 'Credit Card' },
                { id: 'Net 30', value: 'Net 30' }
              ]}
              disabled={action === 'update' && !vendor}
              validator={validator}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DropDown
              label="Payment Method"
              inputName="default_payment_method"
              optionText="Choose..."
              value={data.default_payment_method || ''}
              required
              onChange={handleChange}
              options={[
                { id: 'Bill Pay', value: 'Bill Pay' },
                { id: 'ACH', value: 'ACH' },
                { id: 'Wire', value: 'Wire' },
                { id: 'Live Check', value: 'Live Check' },
                { id: 'Credit Card', value: 'Credit Card' }
              ]}
              disabled={action === 'update' && !vendor}
              validator={validator}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DropDown
              label="Billing Account"
              inputName="billing_account"
              optionText="Choose..."
              value={data.billing_account || ''}
              required
              onChange={handleChange}
              options={billingAccounts}
              disabled={action === 'update' && !vendor}
              validator={validator}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              labelText="Tax ID"
              name="tax_ID"
              placeholder="Tax ID"
              value={data.tax_ID}
              onChange={handleChange}
              maxlength={10}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneNumberInput
              name="phone_number"
              handleChange={handleChange}
              disabled={action === 'update' && !vendor}
              value={data.phone_number}
              isRequired
              validator={validator}
              validationString="required|phoneNumber"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              labelText="Email"
              name="email"
              placeholder="Email"
              value={data.email}
              onChange={handleChange}
              validator={validator}
              validationString="required|email"
              isRequired={true}
              disabled={action === 'update' && !vendor}
            />
          </Grid>
          <Grid item xs={12}>
            <GooglePlacesAutocompleteComp
              labelText="Address"
              name="billed_from_address1"
              value={data.billed_from_address1 || ''}
              onChange={handleChange}
              isRequired={true}
              validator={validator}
              disabled={action === 'update' && !vendor}
              getAddressComponent={(address) => updateAddress(address, 'billed')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={({ target: { checked } }) =>
                    onCheckShippedAddressAsBilledAddress(checked)
                  }
                  checked={shippedFromAddrIsSameAsBilledFromAddr}
                />
              }
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: theme.palette.secondary.main
                }
              }}
              label="Check if different from billing address"
            />
          </Grid>
          <Grid item xs={12}>
            <GooglePlacesAutocompleteComp
              validator={validator}
              labelText="Address"
              name="shipped_from_address1"
              value={data.shipped_from_address1 || ''}
              onChange={handleChange}
              isRequired={true}
              disabled={(action === 'update' && !vendor) || !shippedFromAddrIsSameAsBilledFromAddr}
              getAddressComponent={(address) => updateAddress(address, 'shipped')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              labelText="Accounting Notes"
              name="notes"
              placeholder="Add Notes"
              value={data.notes}
              onChange={handleChange}
              isTextarea
              disabled={action === 'update' && !vendor}
              maxlength={descriptionMaxLength}
            />
          </Grid>
          {action === 'update' && (
            <Grid item xs={12}>
              <DropDown
                label="Active Status"
                inputName="is_active"
                optionText="Choose..."
                value={data?.is_active ? 'true' : 'false'}
                required
                onChange={handleChange}
                options={[
                  { id: 'true', value: 'Active' },
                  { id: 'false', value: 'Inactive' }
                ]}
                disabled={action === 'update' && !vendor}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  }
);
VendorForm.displayName = 'VendorForm';
