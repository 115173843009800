import { Box, IconButton } from '@mui/material';
import { estimate, estimateItem, getAllCustomers, getAllUsers } from 'api/index';
import type {
  Customer,
  Estimate,
  EstimateItem,
  GetEstimateItemRequest,
  GetEstimateRequestInterface,
  GetOcrPurchaseOrders,
  PatchEstimateInterface,
  PatchEstimateItemRequest,
  PostEstimateItemRequest,
  User
} from 'api/types';
import DeleteIcon from 'assets/images/delete-icon.svg';
import Attachment from 'assets/images/attachment-icon.svg';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import CreateCustomer from 'pages/AdminHub/CustomerWorkspace/components/CreateCustomer';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useCommonStore } from 'store/index';
import { dateFormatter, roundToTwoDecimalPlaces, tabTitle } from 'utils/index';
import AddEstimateItemModal from './components/addEstimateItemModal';
import EstimateItems from './components/EstimateItems';
import EstimatesList from './components/EstimatesList';
import InitiateEstimateDrawer from './components/InitiateEstimateDrawer';
import NoDocuments from './components/NoDocuments';
import AWS from 'aws-sdk';
import config from 'config/index';
import PdfView from 'assets/images/pdf-icon.svg';
import EditableTableCell from 'components/EditableTableCell';
import {
  bidTypes,
  dealOwners,
  dealSources,
  descriptionMaxLength,
  jobTypes,
  nameMaxLength
} from 'utils/constants';
import AttachmentsModal from 'components/AttachmentModal';

export interface PaginationDataType {
  pageNumber: string;
  pageSize: string;
  searchText: string;
}

export default function EstimateWorkspace() {
  const [isloadingEstimateSection, setIsloadingEstimateSection] = useState<boolean>(false);
  const [isloadingEstimateItemSection, setIsloadingEstimateItemSection] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedEstimateData, setSelectedEstimateData] = useState<Estimate>();
  const [estimatesList, setEstimatesList] = useState<Estimate[]>([]);
  const [estimatesTotalCount, setEstimatesTotalCount] = useState<number>(0);
  const [estimatesListPaginationData, setEstimatesListPaginationData] =
    useState<PaginationDataType>({ pageNumber: '1', pageSize: '10', searchText: '' });
  const [isOpenInitiateEstimateDrawer, setInitiateEstimateDrawer] = useState<boolean>(false);
  const [isOpenCreateCustomerDrawer, setCreateCustomereDrawer] = useState<boolean>(false);
  const [allCustomers, setAllCustomers] = useState<DropdownOptionType[]>([]);
  const [allEstimators, setAllEstimators] = useState<DropdownOptionType[]>([]);
  const [estimateItemsList, setEstimateItemsList] = useState<EstimateItem[]>([]);
  const [isOpenAddEstimateItemModal, setAddEstimateItemModal] = useState<boolean>(false);
  const [modalImage, setModalImage] = useState<Estimate | null>(null);
  const [selectedData, setSelectedData] = useState([]);
  const [estimateItemsListPaginationData, setEstimateItemsListPaginationData] =
    useState<PaginationDataType>({ pageNumber: '1', pageSize: '10', searchText: '' });
  const [createJob, setCreateJob] = useState<string | null>(null);
  const [headerValues, setHeaderValues] = useState<{ [key: string]: number }>({});
  const [apiInProgress, setApiInProgress] = useState<any>({});
  tabTitle('Estimate Workspace');
  const setComponentHeading = useCommonStore((state) => state.setComponentHeading);

  useEffect(() => {
    setComponentHeading('Estimate Workspace');
  }, []);

  useEffect(() => {
    const userRoles: any = localStorage.getItem('userRoles');
    const userRolesArr: string[] = userRoles?.split(',');
    if (userRolesArr.length === 1 && userRolesArr[0] === 'admin') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    setSelectedUser(localStorage.getItem('userId') || '');
  }, []);

  useEffect(() => {
    fetchEstimates();
    fetchEstimators();
  }, []);

  useEffect(() => {
    if (selectedEstimateData?.estimate_id) fetchEstimateItems();
  }, [selectedEstimateData]);

  useEffect(() => {
    fetchEstimates();
  }, [estimatesListPaginationData, isAdmin, selectedUser]);

  const fetchEstimates = () => {
    try {
      if (typeof isAdmin === 'boolean' && typeof selectedUser === 'string') {
        setIsloadingEstimateSection(true);
        const body: GetEstimateRequestInterface = {
          http_method: 'GET',
          data: {
            ...(!isAdmin && { userId: selectedUser }),
            ...estimatesListPaginationData
          }
        };

        estimate(body)
          .then((res) => {
            const sortedEstimates = res.data.map((estimate: Estimate) => ({
              ...estimate,
              zones: estimate.zones
                ?.sort((a, b) => Number(a.zone_id) - Number(b.zone_id))
                ?.map((zone) => ({
                  ...zone,
                  scenes: zone.scenes?.sort((a, b) => Number(a.scene_id) - Number(b.scene_id))
                }))
            }));

            setEstimatesList(sortedEstimates);
            setEstimatesTotalCount(res.totalCount);
          })
          .catch((err) => {
            console.error('Error on fetching estimates', err);
          })
          .finally(() => {
            setIsloadingEstimateSection(false);
          });
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const fetchCustomers = async () => {
    try {
      const getCustomers = await getAllCustomers();
      const customerOptions: DropdownOptionType[] = getCustomers.map((item: Customer) => ({
        id: item.customer_id ?? '',
        value: item.customer_name ?? ''
      }));
      setAllCustomers(customerOptions);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const fetchEstimators = async () => {
    try {
      const getEstimators = await getAllUsers();
      const estimatorOptions: DropdownOptionType[] = getEstimators.data.map((item: User) => ({
        id: item.user_id ?? '',
        value: `${item.first_name} ${item.last_name}`
      }));
      setAllEstimators(estimatorOptions);
      fetchCustomers();
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const onSelectEstimate = (row) => {
    setSelectedEstimateData(row);
  };

  const fetchEstimateItems = () => {
    if (selectedEstimateData) {
      setIsloadingEstimateItemSection(true);
      const body: GetEstimateItemRequest = {
        http_method: 'GET',
        data: {
          estimateId: selectedEstimateData?.estimate_id
        }
      };
      estimateItem(body)
        .then((res) => {
          setEstimateItemsList(res);
        })
        .catch((err) => {
          console.error('err', err);
        })
        .finally(() => {
          setIsloadingEstimateItemSection(false);
        });
    }
  };

  const postEstimateItem = async (data: EstimateItem) => {
    try {
      setIsloadingEstimateItemSection(true);
      const body: PostEstimateItemRequest = {
        http_method: 'POST',
        data: {
          ...data
        }
      };
      estimateItem(body)
        .then((res) => {
          toast.success('Estimate Item added successfully!', {
            position: 'top-center'
          });
          fetchEstimateItems();
        })
        .catch((err) => {
          console.error('err', err);
          toast.error(err, {
            position: 'top-center'
          });
        })
        .finally(() => {
          setIsloadingEstimateItemSection(false);
        });
      //
      setAddEstimateItemModal(false);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const EstimateItemLink = (row: EstimateItem) => {
    const handleClick = (e) => {
      e.preventDefault();

      const serializableData = {
        estimate_item_id: row.estimate_item_id,
        estimate_item_name: row.estimate_item_name
      };

      const newTab = window.open('/job-estimation/estimate-item-workspace', '_blank');

      if (newTab) {
        newTab.onload = () => {
          newTab.history.replaceState(
            { data: serializableData },
            '',
            '/job-estimation/estimate-item-workspace'
          );
        };
      }
    };

    return (
      <span className="url" onClick={handleClick}>
        {row.estimate_item_name}
      </span>
    );
  };

  const onEditingComplete = (dataToUpdate: Estimate, cellId: string) => {
    setApiInProgress((prev) => ({ ...prev, [cellId]: false }));

    const updatedData: Estimate[] = estimatesList.map((d) =>
      d.estimate_id === dataToUpdate.estimate_id ? { ...d, ...dataToUpdate } : d
    );

    setEstimatesList(updatedData);

    const body: PatchEstimateInterface = {
      http_method: 'PATCH',
      data: {
        ...dataToUpdate
      }
    };
    estimate(body)
      .then((res) => {
        toast.success('Estimate updated successfully!', {
          position: 'top-center'
        });
      })
      .catch((err) => {
        console.error('err', err);
        toast.error(err, {
          position: 'top-center'
        });
      });
  };

  function getPdf(param) {
    AWS.config.update({
      accessKeyId: localStorage.getItem('accessKeyId') ?? '',
      secretAccessKey: localStorage.getItem('secretAccessKey') ?? '',
      sessionToken: localStorage.getItem('sessionToken') ?? '',
      region: config.awsRegion
    });
    const s3 = new AWS.S3();
    const params = {
      Bucket: config.awsS3AssetsBucketName,
      Key: param.s3_uri ?? ''
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        console.error(err);
      } else if (data.Body) {
        const pdfBlob = new Blob([data.Body as BlobPart], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank')?.focus();
      } else {
        console.error('No data.Body found');
      }
    });
  }

  const estimatesListColumns = [
    {
      name: '',
      prop: 'select',
      data: (row) => {
        const fileExtension = row.s3_uri?.split('.').pop();
        return (
          <div className="icon-div">
            <input
              type="radio"
              name="rowSelect"
              onChange={() => onSelectEstimate(row)}
              checked={selectedEstimateData?.estimate_id == row.estimate_id}
              className="custom-radio"
            />

            <div style={{ marginLeft: '15px' }}>
              {fileExtension ? (
                fileExtension === 'pdf' ? (
                  <img
                    width="15px"
                    onClick={() => getPdf(row)}
                    src={PdfView}
                    className="img-fluid link-icon ms-1"
                    alt="PDF Icon"
                  />
                ) : fileExtension === 'png' ||
                  fileExtension === 'jpg' ||
                  fileExtension === 'jpeg' ? (
                  <img
                    onClick={() => setModalImage(row)}
                    src={Attachment}
                    className="img-fluid link-icon"
                    alt="Image Icon"
                  />
                ) : null
              ) : null}
            </div>
          </div>
        );
      }
    },
    {
      prop: 'estimate_id',
      name: 'Estimate ID',
      data: 'estimate_id'
    },
    {
      prop: 'job_name',
      name: 'Job Name',
      data: 'job_name'
    },
    {
      prop: 'job_size',
      name: 'Job Size',
      data: 'job_size'
    },
    {
      prop: 'total',
      name: 'Total',
      data: (row) => `$${roundToTwoDecimalPlaces(row.total ?? 0)}`
    },
    {
      prop: 'estimate_items',
      name: 'Estimate Items',
      data: (row) => `${row.estimate_items ?? 0}`
    },
    {
      prop: 'customer',
      name: 'Customer',
      data: (row) => row.customer.customer_name
    },
    {
      prop: 'assignee',
      name: 'Assignee',
      data: (row) => `${row.estimator.first_name} ${row.estimator.last_name}`
    },
    {
      prop: 'bid_type',
      name: 'Bid Type',
      data: (row) => {
        const cellId = `bid_type-${row.estimate_id}`;
        return (
          <EditableTableCell
            id={cellId}
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  bid_type: bidTypes.find((v) => v.id === val)?.value || ''
                },
                cellId
              )
            }
            isDropdown
            key={cellId}
            options={bidTypes}
            value={row.bid_type || ''}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
          />
        );
      }
    },
    {
      prop: 'deal_source',
      name: 'Deal Source',
      data: (row) => {
        const cellId = `deal_source-${row.estimate_id}`;
        return (
          <EditableTableCell
            id={`deal-source${row.estimate_id}`}
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  deal_source: dealSources.find((v) => v.id === val)?.value ?? ''
                },
                cellId
              )
            }
            isDropdown
            key={cellId}
            options={dealSources}
            value={row?.deal_source || ''}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress({
                [cellId]: val
              })
            }
          />
        );
      }
    },
    {
      prop: 'job_type',
      name: 'Job Type',
      data: (row) => {
        const cellId = `job_type-${row.estimate_id}`;
        return (
          <EditableTableCell
            id={`job-type${row.estimate_id}`}
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  job_type: jobTypes.find((v) => v.id === val)?.value ?? ''
                },
                cellId
              )
            }
            isDropdown
            key={cellId}
            options={jobTypes}
            value={row.job_type || ''}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress({
                [cellId]: val
              })
            }
          />
        );
      }
    },
    {
      prop: 'job_value',
      name: 'Job Value',
      data: (row) => {
        const cellId = `job_value-${row.estimate_id}`;
        return (
          <EditableTableCell
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  job_value: val
                },
                cellId
              )
            }
            key={cellId}
            isPrice={true}
            name="job_value"
            type="number"
            value={row.job_value ? `$${row.job_value}` : `$${0}` || ''}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress({
                [cellId]: val
              })
            }
          />
        );
      }
    },
    {
      prop: 'deal_owner',
      name: 'Deal Owner',
      data: (row) => {
        const cellId = `deal_owner-${row.estimate_id}`;
        return (
          <EditableTableCell
            id={`deal-owner${row.estimate_id}`}
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  deal_owner: dealOwners.find((v) => v.id === val)?.value ?? ''
                },
                cellId
              )
            }
            isDropdown
            key={cellId}
            options={dealOwners}
            value={row.deal_owner || ''}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress({
                [cellId]: val
              })
            }
          />
        );
      }
    },
    {
      prop: 'description',
      name: 'Description',
      data: (row) => {
        const cellId = `description-${row.estimate_id}`;
        return (
          <EditableTableCell
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  description: val
                },
                cellId
              )
            }
            type="text"
            key={cellId}
            value={row.description || ''}
            maxlength={descriptionMaxLength}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress({
                [cellId]: val
              })
            }
          />
        );
      }
    },
    {
      prop: 'pricing_due_date',
      name: 'Pricing Due Date',
      data: (row) => {
        const cellId = `pricing_due_date-${row.estimate_id}`;
        return (
          <EditableTableCell
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  pricing_due_date: val
                },
                cellId
              )
            }
            type="date"
            key={cellId}
            value={row.pricing_due_date ? dateFormatter(row.pricing_due_date, 'date') : ''}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress({
                [cellId]: val
              })
            }
          />
        );
      },
      dateFormat: 'date'
    },
    {
      prop: 'anticipated_start_date',
      name: 'Anticipated Start Date',
      data: (row) => {
        const cellId = `anticipated_start_date-${row.estimate_id}`;
        return (
          <EditableTableCell
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  anticipated_start_date: val
                },
                cellId
              )
            }
            type="date"
            key={cellId}
            value={
              row.anticipated_start_date ? dateFormatter(row.anticipated_start_date, 'date') : ''
            }
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress({
                [cellId]: val
              })
            }
          />
        );
      },
      dateFormat: 'date'
    },
    {
      prop: 'anticipated_ship_date',
      name: 'Anticipated Ship Date',
      data: (row) => {
        const cellId = `anticipated_start_date-${row.estimate_id}`;
        return (
          <EditableTableCell
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  anticipated_ship_date: val
                },
                cellId
              )
            }
            type="date"
            key={cellId}
            value={
              row.anticipated_ship_date ? dateFormatter(row.anticipated_ship_date, 'date') : ''
            }
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress({
                [cellId]: val
              })
            }
          />
        );
      },
      dateFormat: 'date'
    },
    {
      prop: 'client_contact_name',
      name: 'Client Contact Name',
      data: (row) => {
        const cellId = `client_contact_name-${row.estimate_id}`;
        return (
          <EditableTableCell
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  client_contact_name: val
                },
                cellId
              )
            }
            type="text"
            key={cellId}
            value={row.client_contact_name || ''}
            maxlength={nameMaxLength}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress({
                [cellId]: val
              })
            }
          />
        );
      }
    },
    {
      prop: 'client_contact_phone',
      name: 'Client Contact Phone',
      data: (row) => {
        const cellId = `client_contact_phone-${row.estimate_id}`;
        return (
          <EditableTableCell
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  client_contact_phone: val
                },
                cellId
              )
            }
            type="number"
            key={cellId}
            value={row.client_contact_phone || ''}
            maxlength={nameMaxLength}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress({
                [cellId]: val
              })
            }
          />
        );
      }
    },
    {
      prop: 'client_contact_email',
      name: 'Client Contact Email',
      data: (row) => {
        const cellId = `client_contact_email-${row.estimate_id}`;
        return (
          <EditableTableCell
            onEditComplete={(val) =>
              onEditingComplete(
                {
                  estimate_id: row.estimate_id,
                  client_contact_email: val
                },
                cellId
              )
            }
            type="text"
            key={cellId}
            value={row.client_contact_email || ''}
            maxlength={nameMaxLength}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress({
                [cellId]: val
              })
            }
          />
        );
      }
    },
    {
      prop: 'link_to_dropbox',
      name: 'Link To DropBox',
      data: () => (
        <a href="https://www.dropbox.com" target="_blank" rel="noopener noreferrer" className="url">
          Go To Dropbox
        </a>
      )
    }
  ];

  const estimateItemsListColumns = [
    {
      name: 'Action',
      prop: 'select',
      data: (row) => (
        <IconButton color="error">
          <img src={DeleteIcon} />
        </IconButton>
      )
    },
    {
      prop: 'estimate_item',
      name: 'Estimate Item',
      data: (row: EstimateItem) => EstimateItemLink(row)
    },
    {
      prop: 'quantity',
      name: 'Quantity',
      data: 'quantity'
    },
    {
      prop: 'total',
      name: 'Total',
      data: (row: EstimateItem) => `$${row.total ?? 0.0}`
    },
    {
      prop: 'mtls',
      name: 'FAB MTLS',
      data: (row: EstimateItem) => `$${row.material_fabrication_total ?? 0.0}`
    },
    {
      prop: 'total_mtls_percentage',
      name: `Total MTLS: ${headerValues['total_mtls_percentage'] || 0.0}%`,
      data: (row: EstimateItem) => `$${row.material_total_percentage ?? row.material_total}`
    },
    {
      prop: 'non_value',
      name: 'Non - Value',
      data: (row: EstimateItem) => `$${row.non_value_total ?? 0.0}`
    },
    {
      prop: 'non_value_mtls_percentage',
      name: `Non-Value MTLS: ${headerValues['non_value_mtls_percentage'] || 0.0}%`,
      data: (row: EstimateItem) => `$${row.non_value_total_percentage ?? row.non_value_total}`
    },
    {
      prop: 'material_paint_total',
      name: 'Paint',
      data: (row: EstimateItem) => `$${row.material_paint_total ?? 0.0}`
    },
    {
      prop: 'fabrication_hours_per',
      name: `Fabrication: @${headerValues['fabrication_hours_per'] || 0.0}`,
      data: (row: EstimateItem) => `$${row.fabrication_hours_per ?? 0.0}`
    },
    {
      prop: 'paint_hours_per',
      name: `Paint: @${headerValues['paint_hours_per'] || 0.0}`,
      data: (row: EstimateItem) => `$${row.paint_hours_per ?? 0.0}`
    },
    {
      prop: 'media_hours_per',
      name: `Media: @${headerValues['media_hours_per'] || 0.0}`,
      data: (row: EstimateItem) => `$${row.media_hours_per ?? 0.0}`
    },
    {
      prop: 'graphics_hours_per',
      name: `Graphics: @${headerValues['graphics_hours_per'] || 0.0}`,
      data: (row: EstimateItem) => `$${row.graphics_hours_per ?? 0.0}`
    },
    {
      prop: 'pm_hours_per',
      name: `PM: @${headerValues['pm_hours_per'] || 0.0}`,
      data: (row: EstimateItem) => `$${row.pm_hours_per ?? 0.0}`
    },
    {
      prop: 'design_hours_per',
      name: `Design: @${headerValues['design_hours_per'] || 0.0}`,
      data: (row: EstimateItem) => `$${row.design_hours_per ?? 0.0}`
    },
    {
      prop: 'install_hours_per',
      name: `Install: @${headerValues['install_hours_per'] || 0.0}`,
      data: (row: EstimateItem) => `$${row.install_hours_per ?? 0.0}`
    }
  ];

  const handleHeaderValue = (id: string, value: number) => {
    setHeaderValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));

    if (id === 'total_mtls_percentage') {
      const updatedItems = estimateItemsList.map((item: EstimateItem) => {
        if (item.material_total) {
          const percentageValue = (Number(item.material_total) * value) / 100;
          return {
            ...item,
            material_total_percentage: (Number(item.material_total) + percentageValue).toFixed(2)
          };
        }
        return item;
      });
      setEstimateItemsList(updatedItems);
    } else if (id === 'non_value_mtls_percentage') {
      const updatedItems = estimateItemsList.map((item: EstimateItem) => {
        if (item.non_value_total) {
          const percentageValue = (Number(item.non_value_total) * value) / 100;
          return {
            ...item,
            non_value_total_percentage: (Number(item.non_value_total) + percentageValue).toFixed(2)
          };
        }
        return item;
      });
      setEstimateItemsList(updatedItems);
    }
  };

  const customerCreationCallBack = async () => {
    await fetchCustomers();
    setCreateCustomereDrawer(false);
    setInitiateEstimateDrawer(true);
  };

  const estimateCreationCallBack = () => {
    fetchEstimates();
  };

  return (
    <>
      <EstimatesList
        estimatesListColumns={estimatesListColumns}
        estimatesList={estimatesList}
        totalCount={estimatesTotalCount}
        setSearchStr={(val) => {
          setEstimatesListPaginationData({
            ...estimatesListPaginationData,
            searchText: val
          });
        }}
        isLoading={isloadingEstimateSection}
        setCreateJob={setCreateJob}
        setInitiateEstimateDrawer={setInitiateEstimateDrawer}
        setPaginationData={setEstimatesListPaginationData}
      />
      {selectedEstimateData && !isloadingEstimateItemSection ? (
        <>
          <EstimateItems
            isLoading={isloadingEstimateItemSection}
            setAddEstimateItemModal={setAddEstimateItemModal}
            estimateData={selectedEstimateData}
            columns={estimateItemsListColumns}
            items={estimateItemsList}
            setPaginationData={setEstimateItemsListPaginationData}
            headerValues={headerValues}
            handleHeaderValue={handleHeaderValue}
          />
        </>
      ) : (
        <Box sx={{ marginY: '15px' }}>
          <NoDocuments
            text={'Please Select Estimation from above to view more information'}
            isLoading={isloadingEstimateItemSection}
          />
        </Box>
      )}
      {isOpenInitiateEstimateDrawer && (
        <InitiateEstimateDrawer
          IsOpen={isOpenInitiateEstimateDrawer}
          setOpen={setInitiateEstimateDrawer}
          allCustomers={allCustomers}
          allEstimators={allEstimators}
          setCreateCustomereDrawer={setCreateCustomereDrawer}
          successCallBack={estimateCreationCallBack}
          bidTypes={bidTypes}
          dealSources={dealSources}
          jobTypes={jobTypes}
          dealOwners={dealOwners}
        />
      )}
      {isOpenCreateCustomerDrawer && (
        <CreateCustomer
          open={isOpenCreateCustomerDrawer}
          setOpen={setCreateCustomereDrawer}
          action="create"
          successCallBack={customerCreationCallBack}
          cancelCallBack={() => {
            setCreateCustomereDrawer(false);
            setInitiateEstimateDrawer(true);
          }}
        />
      )}
      {isOpenAddEstimateItemModal && selectedEstimateData && (
        <AddEstimateItemModal
          isLoading={isloadingEstimateItemSection}
          isOpen={isOpenAddEstimateItemModal}
          setOpen={setAddEstimateItemModal}
          estimateData={selectedEstimateData}
          submitData={postEstimateItem}
        />
      )}
      {modalImage?.s3_uri && (
        <AttachmentsModal attachements={modalImage?.s3_uri} onClose={() => setModalImage(null)} />
      )}
    </>
  );
}
